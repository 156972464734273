/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onClientEntry = async () => {
  // IntersectionObserver polyfill for gatsby-background-image (Safari, IE)
  if (!(`IntersectionObserver` in window)) {
    await import(`intersection-observer`)
  }
}

exports.onInitialClientRender = () => {
  document.getElementById("___gatsby").style.display = "block"
  setTimeout(function() {
    document.getElementById("___loader").style.display = "none"
  }, 200)
}